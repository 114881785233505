<!-- 兑换 -->

<template>
  <div class="exchange-page">
    <div class="content">
      <div class="exchange-block">
        <div class="select-line">
          <!-- <div class="curent-item" @click="showSelect = !showSelect"> -->
          <div class="curent-item">
            <img class="coin" src="../assets/images/icon-eth-s.svg" alt="" />
            <div class="word f32 font-m color-333">ETH</div>
            <!-- <img class="icon" src="../assets/images/arraw-down.svg" alt="" /> -->
          </div>
          <div class="arraw">
            <img src="../assets/images/arraw.svg" alt="" />
          </div>
          <!-- <div class="curent-item" @click="showSelect = !showSelect"> -->
          <div class="curent-item">
            <img class="coin" src="../assets/images/usdt-logo.png" alt="" />
            <div class="word f32 font-m color-333">USDT</div>
            <!-- <img class="icon" src="../assets/images/arraw-down.svg" alt="" /> -->
          </div>
          <div class="select-list" v-if="showSelect">
            <div
              class="select-item"
              v-for="item in option1"
              :key="item.value"
              @click="showSelect = !showSelect"
            >
              <img :src="item.icon" alt="" />
              <div class="word f28 font-m color-666">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="exchange-box">
          <div class="label f28 color-666 font-m">{{ $i18n.t("ex_quantity") }}</div>
          <div class="input-box">
            <input class="f32" v-model="money" type="number" />
            <div class="word color-333 f28 font-m" @click="fullEx">
              {{ $i18n.t("ex_redeem_all") }}
            </div>
          </div>
          <div class="input-tip f28 color-999">
            {{ $i18n.t("ex_convertible_quantity") }}:
            <span class="color-333 font-m">{{ withdrawableETH }} ETH</span>
          </div>
        </div>
        <div class="exchange-box">
          <div class="label f28 color-666 font-m">{{ $i18n.t("ex_convertible") }}USDT</div>
          <div class="input-box">
            <div class="uninput">
              <img src="../assets/images/usdt-logo.png" alt="" />
              <div class="num color-999 f32">{{ num3 }}</div>
            </div>
            <div class="word color-333 f28 font-m">USDT</div>
          </div>
        </div>
        <div class="exchange-tip">
          {{ $i18n.t("ex_subscription_ratio") }} :1 ETH = {{ ethPrice }} USDT
        </div>
        <button class="btn send-btn font-m" @click="confirmEx" :disabled="isDisabled">
          {{ $i18n.t("ex_confirm_redemption") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    return (num).toFixed(18).replace(/\.?0+$/, "");
}
export default {
  data() {
    return {
      isDisabled: false,
      userInfo: {},
      ethPrice: 0,
      withdrawableETH: null,
      showSelect: false,
      money: 0,
      value1: 0,
      value2: 0,
      option1: [
        // {
        //   text: "BTC",
        //   value: 0,
        //   icon: require("../assets/images/btc.png"),
        // },
        {
          text: "ETH",
          value: 1,
          icon: require("../assets/images/icon-eth-s.svg"),
        },
        {
          text: "USDT",
          value: 2,
          icon: require("../assets/images/icon-link2.png"),
        },
      ],
      option2: [
        {text: "USDT", value: 1, icon: require("../assets/images/usdt-logo.png")},
        {text: "ETH", value: 2, icon: require("../assets/images/icon-eth-s.svg")},
      ],
      // systemConfig: {
      //   ethExchageRate: '' // ETH兑换比例
      // }
    };
  },
  computed: {
    num3() {
      if (this.money == null || this.money == "") {
        // this.money = 0
        return 0;
      } else {
        return this.substr6(parseFloat(this.money) * parseFloat(this.ethPrice))
      }
    },
    systemConfig() {
      return this.$store.state.user.sysConfig
    }
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    substr6(val) {
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    },
    fullEx() {
      this.money = this.withdrawableETH;
    },
    async confirmEx() {
      if (this.money == null || this.money == 0) {
        this.$toast(this.$i18n.t("ex_empty"));
        return;
      }
      if (parseFloat(this.money) > parseFloat(this.withdrawableETH)) {
        this.$toast(this.$i18n.t("ex_no_quantity"));
        return;
      }
      this.isDisabled = true;
      try {
        const res = await this.$http.postUserSwapToken({amount: parseFloat(this.money)});
        if (res.code === 0) {
          this.$toast(this.$i18n.t(res.msg));
          setTimeout(() => {
            this.$router.push("/record?status=2");
          }, 500)
        }
        this.isDisabled = false;
      } catch (error) {
        this.isDisabled = false;
      }
    },
    getUserInfo() {
      let that = this;
      this.$http.getUserInfo().then((res) => {
        console.log(res);
        if (res.code === 0) {
          that.userInfo = res.data;
          that.withdrawableETH = this.substr6(res.data.withdrawableETH)
          that.ethPrice = res.data.ethPrice;
        }
      });
    },
  },
};
</script>

<style lang="less">
@bg1: "../assets/images/btc.png";
@bg2: "../assets/images/usdt-logo.png";
.exchange-page {
  padding-top: 56px;
  .content {
    .exchange-block {
      .select-line {
        margin-bottom: 0.48rem;
        padding: 0.3rem 0;
        border-radius: 12px;
        border: 1px solid #eee;
        display: flex;
        justify-content: center;
        position: relative;
        .curent-item {
          display: flex;
          height: 0.6rem;
          line-height: 0.6rem;
          .coin {
            width: 0.4rem;
            height: 0.4rem;
            margin-top: 0.1rem;
          }
          .word {
            margin: 0 0.1rem;
          }
          .icon {
            width: 0.3rem;
            height: 0.3rem;
            margin-top: 0.15rem;
          }
        }
        .arraw {
          height: 0.6rem;
          line-height: 0.5rem;
          img {
            width: 1rem;
            transform: scale(0.4);
          }
        }
        .select-list {
          width: 100%;
          padding: 0.1rem 0.3rem;
          position: absolute;
          border: 1px solid #eee;
          top: 1.2rem;
          border-bottom-left-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem;
          background: #fff;
          z-index: 1;
          .select-item {
            display: flex;
            line-height: 0.8rem;
            img {
              width: 0.4rem;
              height: 0.4rem;
              margin-right: 0.1rem;
              margin-top: 0.2rem;
            }
            .word {
            }
          }
        }
      }
      .exchange-box {
        margin-bottom: 0.6rem;
        .label {
          text-align: left;
          margin-bottom: 0.2rem;
        }
        .input-box {
          display: flex;
          border: 1px solid #eee;
          border-radius: 0.24rem;
          padding: 0 0.2rem;
          .uninput {
            flex: 1;
            display: flex;
            line-height: 0.88rem;
            img {
              width: 0.48rem;
              height: 0.48rem;
              margin-top: 0.2rem;
              margin-right: 0.1rem;
            }
          }
          input {
            flex: 1;
            height: 0.88rem;
          }
          .word {
            line-height: 0.88rem;
            margin-left: 0.3rem;
          }
        }
        .input-tip {
          line-height: 0.4rem;
          margin-top: 0.2rem;
          text-align: left;
        }
      }
    }
  }
}
</style>
